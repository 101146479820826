import React from "react";

import components from "./parts";

const printComponent = (attributes, typeNameToComponent) => {
  if (!components[typeNameToComponent[attributes.__typename]]) {
    return (
      <div>
        {attributes.__typename} not found among {JSON.stringify(typeNameToComponent)}
      </div>
    );
  }

  const Component = components[typeNameToComponent[attributes.__typename]];
  return <Component {...attributes} />;
};

const ContactList = ({ modes, typeNameToComponent }) => {
  return (
    <div className="container mx-auto max-w-screen-xl px-4 pb-5 md:pb-24 ">
      <h1 className={"text-H3 sm:text-H1 text-exploratory-green-400"}>Kontakty</h1>

      <div className="px-0 lg:px-14 pt-2">
        <div className="mt-6 grid grid-cols-1 gap-y-20 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
          {modes
            .filter(i => i)
            .map((mode, i) => (
              <div key={i} className="flex flex-row sm:flex-col md:flex-row items-start sm:items-center md:items-start">
                <div className="sm:ml-10 sm:mr-10 mr-6 flex-shrink-0">
                  {mode
                    .filter(({ __typename }) => typeNameToComponent[__typename] === "Icon")
                    .map(attributes => printComponent(attributes, typeNameToComponent))}
                  {mode.filter(({ __typename }) => typeNameToComponent[__typename] === "Icon").length === 0 && <div className={"w-16 h-16"} />}
                </div>
                <div className={"text-left sm:text-center md:text-left"}>
                  {mode
                    .filter(({ __typename }) => typeNameToComponent[__typename] !== "Icon")
                    .map(attributes => printComponent(attributes, typeNameToComponent))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export { ContactList };
