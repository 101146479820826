import React from "react";

const Text = ({ content }) =>
{
  const segments = content && typeof content === 'string' && content.split("\n") || [];
  return (
    <div className={"text-bigForms"}>
      {segments.map((item, i) => (
        <React.Fragment key={i}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export { Text };
