import React from "react";

const Link = ({ label, link, analytics }) => {
  return (
    <a className={"block my-1 font-bold underline text-H4 text-exploratory-green-400 hover:text-exploratory-green-600"} href={link} {...analytics}>
      {label}
    </a>
  );
};

export { Link };
