import React from "react";
import { graphql } from "gatsby";

import { Helmet } from "react-helmet";
import { ContactList } from "../components/ContactModes/ContactList";
import { Branches } from "../components/Branches";
import { StaticImage } from "gatsby-plugin-image";
import BreadcrumbsV2 from "../components/breadcrumbsV2";
import MainLayout from "../componentsTailwind/MainLayout";

export default ({ data }) => {
  const {
    mnd: {
      contact: {
        data: { attributes },
      },
    },
  } = data;

  const branches = attributes.branches;
  const specialOpenHours = attributes.SpecialOpenHours;
  const specialOpenHoursIcon = attributes.specialOpenHoursIcon;

  return (
    <MainLayout>
      <Helmet>
        <title>Kontakty - MND</title>
        <meta
          name="keywords"
          content="mnd kontakt, mnd kontakty, mnd praha, mnd hodonín, mnd ostrava, mnd pobočky, mnd pobočka, mnd infolinka, mnd zákaznická linka, mnd telefon, mnd adresa"
        />
        <meta
          name="description"
          content={"Chcete poradit? Máte dotaz? Nevíte si rady při změně dodavatele a nevyznáte se v nabídkách? Kontaktujte nás ještě dnes."}
        />
        <meta
          name="og:description"
          content={"Chcete poradit? Máte dotaz? Nevíte si rady při změně dodavatele a nevyznáte se v nabídkách? Kontaktujte nás ještě dnes."}
        />
        <meta property="og:image" content={`https://www.mnd.cz/images/prepis-energii/stehovani-share-image.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Contact' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/kontakt"></link>
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-4 pb-2">
        <BreadcrumbsV2
          pages={[
            {
              name: "Novinky",
              link: "/kontakt",
            },
          ]}
          color={"grey.dark"}
        />
      </div>

      <ContactList
        modes={[
          attributes.ContactPlace1,
          attributes.ContactPlace2,
          attributes.ContactPlace3,
          attributes.ContactPlace4,
          attributes.ContactPlace5,
          attributes.ContactPlace6,
        ]}
        typeNameToComponent={{
          mnd_ComponentComponentItemTitle: "Title",
          mnd_ComponentComponentItemIcon: "Icon",
          mnd_ComponentComponentItemLink: "Link",
          mnd_ComponentComponentItemButton: "Button",
          mnd_ComponentComponentItemSocialLink: "SocialLink",
          mnd_ComponentComponentItemOpenHours: "OpenHours",
          mnd_ComponentComponentItemText: "Text",
          mnd_ComponentComponentItemSeparator: "Separator",
        }}
      />

      <div className={"border-0 border-t border-solid border-grey"}>
        <div className={"max-w-screen-xl mx-auto px-4 lg:px-0 py-9 text-center"}>
          <h4 className={"text-expertise-gray text-lg font-semibold mb-3"}>Sídlo firmy a fakturační údaje</h4>
          <p className={"m-0"}>
            MND&nbsp;Energie&nbsp;a.s., se&nbsp;sídlem Evropská&nbsp;866/71, Vokovice, 160 00 Praha 6 IČO:&nbsp;29137624, DIČ:&nbsp;CZ699003312,
            číslo&nbsp;účtu:&nbsp;107-3954680207/0100
          </p>
        </div>
      </div>

      <Branches branches={branches} specialOpenHours={specialOpenHours} specialOpenHoursIcon={specialOpenHoursIcon} />

      <div className={"bg-grey pb-4"}>
        <div className={"flex flex-row justify-center text-tiny"}>
          <a href="#kontakt" title="Kontakty" className={"text-expertise-gray px-2"}>
            Kontakty
          </a>{" "}
          |{" "}
          <a href="#zakaznicka-centra" className={"text-expertise-gray px-2"} title="Zákaznická centra">
            Zákaznická centra
          </a>
        </div>
      </div>

      <div className={"bg-grey"}>
        <div className={"container mx-auto max-w-screen-xl px-0 lg:px-4"}>
          <StaticImage src={"../images/contact__img.jpg"} alt={"Obrázek pobočky"} />
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ContactQuery {
    mnd {
      contact {
        data {
          attributes {
            BranchesTitle
            ContactPlace1 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                label
                link
                analytics
                analytics
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                content
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
            }
            ContactPlace2 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                content
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
            }
            ContactPlace3 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                id
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                id
                content
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
              ... on mnd_Error {
                __typename
                code
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                label
                link
                analytics
              }
            }
            ContactPlace4 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                content
              }
            }
            ContactPlace5 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                link
                label
                analytics
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                link
                label
                analytics
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                content
              }
            }
            ContactPlace6 {
              ... on mnd_ComponentComponentItemSeparator {
                __typename
                size
              }
              ... on mnd_ComponentComponentItemButton {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemIcon {
                __typename
                type
              }
              ... on mnd_ComponentComponentItemLink {
                __typename
                link
                label
                analytics
              }
              ... on mnd_ComponentComponentItemOpenHours {
                __typename
                branchHour {
                  branchOpenDay
                  branchOpenText
                }
              }
              ... on mnd_ComponentComponentItemSocialLink {
                __typename
                label
                link
                analytics
              }
              ... on mnd_ComponentComponentItemText {
                __typename
                content
              }
              ... on mnd_ComponentComponentItemTitle {
                __typename
                content
              }
            }
            HeadquartersTitle
            specialOpenHoursLabel
            specialOpenHoursIcon
            SpecialOpenHours {
              id
              title
              openHours {
                branchOpenDay
                branchOpenText
              }
            }
            HeadquartersContent
            branches {
              id
              address
              addressLabel
              email
              mapLink
              mapLinkLabel
              openHour {
                branchOpenDay
                branchOpenText
              }
              openLabel
              title
            }
          }
        }
      }
    }
  }
`;
