import React from "react";

const Separator = ({ size }) => {
  return <hr className="m-0 bg-transparent border-0" style={{ height: `${size}px` }} />;
};

Separator.defaultProps = {
  size: 16,
};

export { Separator };
