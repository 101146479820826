import React from "react";
import { Button as StyledButton } from "../../style/Button";

// TODO: button do tailwindu
// <button
//   className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium text-white bg-exploratory-green-400 hover:resource-green-500 focus:outline-none focus:exploratory-green-400 focus:exploratory-green-400"
//   src={link}
// >
//   {label}
// </button>

const Button = ({ label, link, analytics }) => {
  return (
    <StyledButton display={"inline-flex"} as={"a"} variant={"primary"} href={link} py={2} maxWidth={210} my={3} width={1} {...analytics}>
      {label}
    </StyledButton>
  );
};

export { Button };
