import React from "react";
import { IconFacebook } from "../../componentsTailwind/Icons";

const SocialLink = ({ label, link, analytics }) => {
  return (
    <a href={link} className={"flex items-center underline text-exploratory-green-400"} {...analytics}>
      <div className="relative w-5 h-5 inline-block mr-2 bg-grey rounded">
        <IconFacebook className={"absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-4 w-4 fill-white"} />
      </div>
      {label}
    </a>
  );
};

export { SocialLink };
