import { Icon } from "./Icon";
import { Text } from "./Text";
import { OpenHours } from "./OpenHours";
import { Title } from "./Title";
import { Link } from "./Link";
import { SocialLink } from "./SocialLink";
import { Button } from "./Button";
import { Separator } from "./Separator";

export default {
  Icon,
  Text,
  OpenHours,
  Title,
  Link,
  SocialLink,
  Button,
  Separator,
};
