import React from "react";
import { IconAt, IconBriefCase, IconCall, IconComputer, IconEnvelope } from "../../componentsTailwind/Icons";

const Icons = {
  computer: <IconComputer className="w-16 h-16 fill-resource-green-400" />,
  email: <IconAt className="w-16 h-16 fill-resource-green-400" />,
  phone: <IconCall className="w-16 h-16 fill-resource-green-400" />,
  briefcase: <IconBriefCase className="w-16 h-16 fill-grey" />,
  envelope: <IconEnvelope className="w-16 h-16 fill-resource-green-400" />,
};

const Icon = ({ type }) => {
  return Icons[type] ? Icons[type] : null;
};

export { Icon };
