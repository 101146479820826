import React from "react";
import cx from "classnames";
import { IconChristmas, IconEaster, IconLocation, IconSpecial } from "../../componentsTailwind/Icons";
import PropTypes from "prop-types";

const IconRound = ({ type }) => {
  const iconsClasses = "absolute w-8 h-8 fill-exploratory-green-400 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2";

  return (
    <div className={"relative inline-block rounded-full w-14 h-14 mb-4 bg-white"}>
      {type === "location" && <IconLocation className={iconsClasses} />}
      {type === "christmas" && <IconChristmas className={iconsClasses} />}
      {type === "easter" && <IconEaster className={iconsClasses} />}
      {type === "special" && <IconSpecial className={iconsClasses} />}
    </div>
  );
};

IconRound.propTypes = {
  type: PropTypes.string.isRequired,
};

const Branches = props => {
  const { branches, specialOpenHours, specialOpenHoursIcon } = props;
  return (
    <div className={"bg-grey pt-6 md:pt-14 pb-10 mb:pb-20"}>
      <div className="container mx-auto max-w-screen-xl px-4">
        <h2 className={"text-H2 text-exploratory-green-400 text-center font-extralight"}>Navštivte nás</h2>

        {specialOpenHours.length > 0 && (
          <React.Fragment>
            <div className="w-full text-center">
              <IconRound type={specialOpenHoursIcon} />
            </div>

            <div className={cx(specialOpenHours.length > 1 ? "sm:grid-cols-2 lg:grid-cols-2" : "", "grid grid-cols-1 gap-x-6 xl:gap-x-8")}>
              {specialOpenHours.map(open => (
                <div key={open.id} className={cx("flex flex-col items-center text-center text-exploratory-green-400")}>
                  <h3 className="text-H4 text-exploratory-green-40">
                    {open.title.split("\n").map((item, i) => (
                      <React.Fragment key={i}>
                        {item}
                        <br />
                      </React.Fragment>
                    ))}
                  </h3>
                  <div className={"flex"}>
                    <table className="table-auto border-none">
                      {open.openHours.map(hour => {
                        return (
                          <tr>
                            <td className={"border-none py-0 px-2"}>{hour.branchOpenDay}</td>
                            <td className={"border-none py-0 px-2"}>{hour.branchOpenText}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
          {branches.map(branch => (
            <div key={branch.id} className="relative flex flex-col items-start sm:items-center text-center text-exploratory-green-400 pl-16 sm:pl-0">
              <div className={"absolute rounded-full w-14 h-14 mb-4 bg-white left-0 sm:relative sm:left-0"}>
                <IconRound type={"location"} />
              </div>

              <h3 className="contactInfoBlock__item-content-title text-left sm:text-center">
                {branch.title.split("\n").map((item, i) => (
                  <React.Fragment key={i}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </h3>
              <p className="w-full flex flex-row text-left text-sm sm:text-base sm:text-center sm:flex-col sm:align-start">
                <strong className={"w-1/3 sm:w-auto"}>{branch.openLabel}</strong>
                <div>
                  {branch.openHour.map(hour => {
                    return (
                      <span>
                        {hour.branchOpenDay}&nbsp;&nbsp;{hour.branchOpenText}
                      </span>
                    );
                  })}
                </div>
                <br />
              </p>
              <address className="w-full not-italic flex flex-row text-left text-sm sm:text-base sm:flex-col sm:align-start sm:text-center">
                <strong className={"w-1/3 sm:w-auto"}>{branch.addressLabel}</strong>
                {branch.address.split("\n").map((item, i) => (
                  <React.Fragment key={i}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
              </address>

              <p className={"mt-auto flex flex-row w-full text-left text-sm sm:text-base sm:text-center"}>
                <div className={"w-1/3 sm:w-0"}></div>
                <div className={"w-2/3 sm:w-full"}>
                  <a href={`${branch.mapLink}`} target="_blank" className={"text-expertise-gray"}>
                    {branch.mapLinkLabel}
                  </a>
                  <br />
                  <a href={`mailto:${branch.email}`} className={"text-expertise-gray"}>
                    {branch.email}
                  </a>
                </div>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Branches };
